import React from "react"
import Layout from "../../../layouts/PESLayout"
import { StaticImage, GatsbyImage, getImage } from "gatsby-plugin-image"
import { graphql, Link } from "gatsby"

export default function PES({ data }) {
  return (
    <Layout>
      <div className="container mx-auto py-5">
        <div className="row bg-white p-3 shadow rounded-xl">
          <div className="col-lg-8 ">
            <div className="h-100">
              <div className="card-body">
                <div className="d-flex justify-content-center">
                  <StaticImage
                    height={200}
                    src="../../../images/PES_logo.webp"
                    alt="Logo of PES"
                  />
                </div>
                <div className="cardTitle">About PES</div>
                <div className="card-text px-3">
                  The Power & Energy Society (PES) provides the world's largest
                  forum for sharing the latest in technological developments in
                  the electric power industry, for developing standards that
                  guide the development and construction of equipment and
                  systems, and for educating members of the industry and the
                  general public. Members of the Power & Energy Society are
                  leaders in this field, and they — and their employers — derive
                  substantial benefits from involvement with this unique and
                  outstanding association
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="card-body">
              <div className="cardTitle">Mission</div>
              <div className="card-text">
                To be the leading provider of scientific and engineering
                information on electric power and energy for the betterment of
                society, and the preferred professional development source for
                our members
              </div>
            </div>

            <div className="card-body">
              <div className="cardTitle">PES Field of Interest</div>
              <div className="card-text">
                The scope of the Society embraces research, development,
                planning, design, construction, maintenance, installation and
                operation of equipment, structures, materials and power systems
                for the safe, sustainable, economic and reliable conversion,
                generation, transmission, distribution, storage and usage of
                electric energy, including its measurement and control.
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container mx-auto my-4">
        <div className="heading">
          Execom
          <div className="underline"></div>
        </div>
        <div className="row row-cols-1 row-cols-md-2 row-cols-lg-4 justify-content-center">
          <div className="col d-flex flex-column align-items-center my-4">
            <StaticImage
              height={180}
              className="rounded-xl m-1"
              src="../../../images/execom/2022-23/Mr Nithin T.jpg"
              alt="nithin"
            />
            <p className="fs-5 fw-bold mb-0 mt-1">Nithin T</p>
            <p>PES Chapter Advisor</p>
          </div>
          <div className="col d-flex flex-column align-items-center my-4">
            <StaticImage
              height={180}
              className="rounded-xl m-1"
              src="../../../images/execom/2024-25/PES - Chair - Ramil.jpg"
              alt="ramil"
            />
            <p className="fs-5 fw-bold mb-0 mt-1">Mohammed Ramil M</p>
            <p>Chairperson</p>
          </div>
          <div className="col d-flex flex-column align-items-center my-4">
            <StaticImage
              height={180}
              className="rounded-xl m-1"
              src="../../../images/execom/2024-25/PES - Vice Chair - Janeev.jpg"
              alt="janeev"
            />
            <p className="fs-5 fw-bold mb-0 mt-1">Janeev LS</p>
            <p>Vice Chairperson</p>
          </div>
          <div className="col d-flex flex-column align-items-center my-4">
            <StaticImage
              height={180}
              className="rounded-xl m-1"
              src="../../../images/execom/2024-25/PES - Secretary - Amal.jpg"
              alt="amal"
            />
            <p className="fs-5 fw-bold mb-0 mt-1">Amal KL</p>
            <p>Secretary</p>
          </div>
          <div className="col d-flex flex-column align-items-center my-4">
            <StaticImage
              height={180}
              className="rounded-xl m-1"
              src="../../../images/execom/2024-25/PES - WP - Niba Thamanna.jpg"
              alt="niba"
            />
            <p className="fs-5 fw-bold mb-0 mt-1">Niba Thamanna Gafoor</p>
            <p>Women in Power Representative</p>
          </div>
        </div>
      </div>

      <div className="container my-5">
        <div className="heading">
          Events
          <div className="underline"></div>
        </div>
        <div className="row row-cols-1 row-cols-md-2 row-cols-lg-3 align-items-center justify-content-center my-4">
          {data.allMarkdownRemark.edges.map(el => (
            <Link
              key={el.node.frontmatter.title}
              className="col card my-2 shadow"
              to={`../../reg/${el.node.frontmatter.slug}`}
              style={{ maxWidth: "18rem" }}
            >
              <div>
                <GatsbyImage
                  image={getImage(el.node.frontmatter.image)}
                  alt={el.node.frontmatter.title}
                  className="card-img"
                />
              </div>
              <div className="card-body d-flex felx-column center">
                <div className="fw-bold text-center text-dark">
                  {el.node.frontmatter.title}
                </div>
              </div>
            </Link>
          ))}
        </div>
        <div className="d-flex justify-content-center my-4">
          <Link className="btn btn-primary" to="/reg">
            View More Events
          </Link>
        </div>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query PES {
    allMarkdownRemark(
      filter: { frontmatter: { by: { regex: "/PES/i" } } }
      limit: 3
      sort: { fields: frontmatter___date, order: DESC }
    ) {
      edges {
        node {
          frontmatter {
            title
            date
            slug
            by
            image {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
      }
    }
  }
`
